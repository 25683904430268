import React from "react"
//import { graphql } from "gatsby"
import Header from "../components/header"
import SEO from "../components/seo"

const Copyrights = props => {
  // const { data } = props
  //const { nodes } = data.allDirectusPage
  const nodes = []
  const page = nodes.length > 0 ? nodes[0] : {}
  // const donors = [] //props.data.allDirectusCopyrights && props.data.allDirectusCopyrights.nodes ? props.data.allDirectusCopyrights.nodes : []
  /*  const sortedCopyrights = donors.sort((a, b) => {
    const surnameAindex = a.name.lastIndexOf(" ")
    const surnameA = a.name.substring(surnameAindex)
    const surnameBindex = b.name.lastIndexOf(" ")
    const surnameB = b.name.substring(surnameBindex)
    return surnameA > surnameB ? 1 : -1
  }) */
  return (
    <>
      <SEO title="Copyrights" />
      <Header title="Copyrights" donorsLocation={true} />
      <div className="twoPanes donorsPage">
        <div className="pane1">
          <h2 className="bb">
            <span>{page.title}</span>
          </h2>
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
      </div>
    </>
  )
}

/* export const query = graphql`
  query Copyrights {
    allDirectusPage(filter: { id: { eq: "Directus__Page__4" } }) {
      nodes {
        id
        title
        content
        image {
          localFile {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  }
` */
export default Copyrights
